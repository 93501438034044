$(document).ready(function () {
	let _ls = window.localStorage;

	let brand,
		whitePrice,
		cmykPrice,
		prints,
		email,
		phone,
		goodType,
		goodId;

	let price,
		usdRate,
		eurRate;

	let getCurrency = function () {
		$.ajax({
			url: 'https://www.cbr-xml-daily.ru/daily_json.js',
			success: function (msg) {
				let parsed = JSON.parse(msg);
				usdRate = parsed.Valute.USD.Value;
				eurRate = parsed.Valute.EUR.Value;
				console.log(`USD: ${usdRate} / EUR: ${eurRate}`);
				console.log(typeof (usdRate));
				setPrices();
			},
			error: function (msg) {
				console.log(msg);
			}
		});
	}
	getCurrency();

	let setPrices = function () {
		let priceList = {
			IA: {
				all: Math.round(150 * usdRate)
			},
			DUPONT: {
				white: Math.round(167 * eurRate),
				cmyk: Math.round(140 * eurRate)
			},
			FIREBIRD: {
				white: Math.round(0 * usdRate),
				cmyk: Math.round(0 * usdRate)
			},
			DCTEC: {
				white: Math.round(0 * usdRate),
				cmyk: Math.round(0 * usdRate)
			},
			INKSYSTEM: {
				white: Math.round(0 * usdRate),
				cmyk: Math.round(0 * usdRate)
			},
			SMALLCMYK: {
				before: Math.round(270 * usdRate),
				after: Math.round(262 * usdRate)
			},
			SMALL: {
				before: Math.round(360 * usdRate),
				after: Math.round(350 * usdRate)
			},
			MID: {
				before: Math.round(670 * usdRate),
				after: Math.round(637 * usdRate)
			},
			PRO: {
				before: Math.round(1220 * usdRate),
				after: Math.round(1135 * usdRate)
			}
		}
		let table = `
		<tr>
					<th>Бренд</th>
					<th>Цена CMYK 1л</th>
					<th>Цена White 1л</th>
					<th>Производитель</th>
					<th>Время запекания, сек.</th>
					<th>Рейтинг</th>
				</tr>
				<tr>
					<td data-th="Бренд">Image Armor</td>
					<td data-th="Цена CMYK 1л" data-price="IACMYK"> ${priceList.IA.all} ₽</td>
					<td data-th="Цена White 1л" data-price="IAW">${priceList.IA.all} ₽</td>
					<td data-th="Производитель">США</td>
					<td data-th="Время запекания, сек.">35</td>
					<td data-th="Рейтинг">5*</td>
				</tr>
				<tr>
					<td data-th="Бренд">DuPont</td>
					<td data-th="Цена CMYK 1л" data-price="DUCMYK">${priceList.DUPONT.cmyk} ₽</td>
					<td data-th="Цена White 1л" data-price="DUW">${priceList.DUPONT.white} ₽</td>
					<td data-th="Производитель">США</td>
					<td data-th="Время запекания, сек.">120</td>
					<td data-th="Рейтинг">5</td>
				</tr>
				<tr>
					<td data-th="Бренд">Firebird</td>
					<td data-th="Цена CMYK 1л" data-price="FCMYK">17000 ₽</td>
					<td data-th="Цена White 1л" data-price="FW">14000 ₽</td>
					<td data-th="Производитель">США</td>
					<td data-th="Время запекания, сек.">60/120</td>
					<td data-th="Рейтинг">4</td>
				</tr>
				<tr>
					<td data-th="Бренд">DCtec</td>
					<td data-th="Цена CMYK 1л" data-price="DCCMYK">8050 ₽</td>
					<td data-th="Цена White 1л" data-price="DCW">29000 ₽</td>
					<td data-th="Производитель">Китай</td>
					<td data-th="Время запекания, сек.">120</td>
					<td data-th="Рейтинг">3</td>
				</tr>
				<tr>
					<td data-th="Бренд">Ink System</td>
					<td data-th="Цена CMYK 1л" data-price="INKCMYK">5100 ₽</td>
					<td data-th="Цена White 1л" data-price="INKW">13000 ₽</td>
					<td data-th="Производитель">Южная корея</td>
					<td data-th="Время запекания, сек.">150</td>
					<td data-th="Рейтинг">3</td>
				</tr>
		`;
		$('#app-table').html(table);
		$('#cmyk_small').html(`${priceList.SMALLCMYK.after} ₽ <sup>${priceList.SMALLCMYK.before} ₽</sup>`)
		$('#small').html(`${priceList.SMALL.after} ₽ <sup>${priceList.SMALL.before} ₽</sup>`)
		$('#mid').html(`${priceList.MID.after} ₽ <sup>${priceList.MID.before} ₽</sup>`)
		$('#pro').html(`${priceList.PRO.after} ₽ <sup>${priceList.PRO.before} ₽</sup>`)
	}


	let USER = {};

	let getData = {};
	let sendData;
	let toCrmData = {};

	function initUser() {
		if (!_ls.getItem('USER')) {
			USER = {
				id: (Date.now().toString(36) + Math.random().toString(36).substr(2, 10)),
				brand: 'не указан',
				whitePrice: 0,
				cmykPrice: 0,
				prints: 0,
				email: '',
				phone: ''
			}
			_ls.setItem('USER', JSON.stringify(USER));

			// for (var p in USER) {
			//   if (USER.hasOwnProperty(p)) {
			//     p = USER[p]
			//   }
			// }
			// console.log(phone);

			brand = USER.brand;
			whitePrice = USER.whitePrice;
			cmykPrice = USER.cmykPrice;
			prints = USER.prints;
			email = USER.email;
			phone = USER.phone;

		} else {
			USER = JSON.parse(_ls.getItem('USER'))
			brand = USER.brand;
			whitePrice = USER.whitePrice;
			cmykPrice = USER.cmykPrice;
			prints = USER.prints;
			email = USER.email;
			phone = USER.phone;

			// console.log(JSON.parse(_ls.getItem('USER')));
		}
	}
	initUser();


	let addToLocalStorage = function (name, key, value) {
		let existing = localStorage.getItem(name);
		existing = existing ? JSON.parse(existing) : {};
		existing[key] = value;
		localStorage.setItem(name, JSON.stringify(existing));
	};



	$('button[type=submit]').on('click', function (e) {
		e.preventDefault();
	});

	// Animated Scroll
	$('.js-scroll-to-consultation').click(function () {
		animateScroll('consultation')
	})
	$('.js-scroll-to-call').click(function () {
		animateScroll('phone')
	})
	$('.js-scroll-to-order').click(function () {
		animateScroll('order')
	})

	function animateScroll(target) {
		$('html, body').animate({
			scrollTop: $('#' + target + '').offset().top
		}, 1500);
	}
	/////

	$('.js-howMuch').on('input focusout focusin load', function () {
		if (this.value.length > 5) {
			this.value = this.value.slice(0, 5);
		}
		let isValid = false;
		if ($(this).hasClass('js-inks')) {
			$('.step').find('.js-inks').each(function () {
				if (this.value.length <= 3) {
					isValid = false;
					return false;
				} else {
					isValid = true;
				}
			})
		}

		if ($(this).hasClass('js-prints')) {
			if (this.value.length >= 1) {
				isValid = true;
			} else {
				isValid = false;
			}
		}

		if (isValid) {
			$('.step').find('.js-btn-next').attr('disabled', false);
		} else {
			$('.step').find('.js-btn-next').attr('disabled', true);
		}
	})

	$('.step__btn').click(function () {
		let $this = $(this)
		$('.js-btn-next').attr('disabled', true)
		$this.parent('.step').hide();
		$this.parent('.step').next('.step').css('display', 'flex');
		// if ($this.attr('data-black') == 'no') {
		//   $('.js-white').remove();
		// }
		if ($this.attr('data-brand')) {
			brand = $this.attr('data-brand')
			addToLocalStorage('USER', 'brand', brand)
		}
		if ($this.hasClass('js-calculate-inks')) {
			whitePrice = parseInt($('.js-cmyk').val());
			cmykPrice = parseInt($('.js-white').val());
			price = Math.ceil((whitePrice + cmykPrice) / 2 / 1000 * 8);
			addToLocalStorage('USER', 'whitePrice', whitePrice)
			addToLocalStorage('USER', 'cmykPrice', cmykPrice)
		}
		if ($this.hasClass('js-calculate')) {
			prints = parseInt($('.js-prints').val());
			calculate(price, prints);
			addToLocalStorage('USER', 'prints', prints)
		}
	})

	$('.js-faq-toggle').click(function () {
		$(this).toggleClass('i-opened')
	})

	$('.js-order-btn').click(function () {
		let orderType = $(this).attr('data-order')
		$('.b-popup').fadeIn()
	})

	$('.js-popup-close').click(function () {
		$('.b-popup').fadeOut()
	})

	document.onkeydown = function (e) {
		e = e || window.event;
		if (e.keyCode == 27) {
			$('.b-popup').fadeOut()
		}
	};

	function calculate(price, prints) {
		let dif = price - 80;

		let monthlyDif = dif * prints;
		let yearlyDif = monthlyDif * 12;
		let timeDifMinutes = Math.ceil(((150 - 35) * prints) / 60);
		let timeDifHours = Math.ceil(timeDifMinutes / 60);
		let result = '';
		if (dif > 0) {
			if (timeDifMinutes < 59) {
				result = `В месяц Ваша выгода составит <strong>${monthlyDif}₽.</strong><br>А в год экономия выйдет <strong>${yearlyDif}₽.</strong><br> Также Вы сэкономите <strong>${timeDifMinutes}</strong> мин. в месяц.`;
			} else {
				result = `В месяц Ваша выгода составит <strong>${monthlyDif}₽.</strong><br>А в год экономия выйдет в <strong>${yearlyDif}₽.</strong><br> Также Вы сэкономите <strong>${timeDifMinutes}</strong> мин. или <strong>~${timeDifHours}</strong> человекочасов в месяц!`;
			}
		} else {
			if (timeDifMinutes < 59) {
				result = `По всей видимости, чернила, которыми вы печатаете сейчас, стоят дешевле Image Armor. Но настолько ли они яркие и износоустойчивые?  \n И да, перейдя на Image Armor, Вы сэкономите ${timeDifMinutes} минут времени в месяц.`;
			} else {
				result = `По всей видимости, чернила, которыми вы печатаете сейчас, стоят дешевле Image Armor. Но настолько ли они яркие и износоустойчивые?  \n И да, перейдя на Image Armor, Вы сэкономите ${timeDifMinutes} минут времени или ~${timeDifHours} человекочасов в месяц!`;
			}
		}

		$('.js-show-result').html(result)
		sendAnalytics()
	}


	$('.js-input-email').on('input', function () {
		validateEmail()
	})
	$('.js-input-email').on('focusout', function () {
		collectMail()
	})
	$('.js-input-phone').on('input', function () {
		validatePhone($(this))
	})
	$('.js-input-phone').on('focusout', function () {
		collectPhone($(this))
	})

	$('.js-send-btn').click(function () {
		let input = $(this).parent('form').find('.js-input-phone')
		if ($(this).attr('disabled', false)) {
			collectMail()
			collectPhone(input)
			sendAnalytics()
			sendToEmail()
			sendToCrm('newClient')
		}
	})

	$('.js-order-btn').click(function () {
		getOrder($(this))
	})

	$('.js-send-order-btn').click(function () {
		let input = $(this).parent('form').find('.js-input-phone')
		collectPhone(input)
		sendToEmail()
		sendToCrm('newOrder')
	})


	function validateEmail() {

		let pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
		if (pattern.test($('#email').val())) {
			$('#email').css({
				'outline-color': 'green',
				'border-color': 'green'
			})
			$('.js-send-btn').attr('disabled', false)
			return true;
		} else {
			$('#email').css({
				'outline-color': 'red',
				'border-color': 'red'
			})
			// $('.js-send-btn').attr('disabled', true)
			return false
		}
	}

	function validatePhone(element) {
		let pattern = /^([-+0-9 ()]{10,})$/i;
		if (pattern.test(element.val())) {
			$(element).css({
				'outline-color': 'green',
				'border-color': 'green'
			})
			element.parents('form').find('button').attr('disabled', false)
			return true
		} else {
			element.css({
				'outline-color': 'red',
				'border-color': 'red'
			})
			element.parents('form').find('button').attr('disabled', true)
			return false
		}
	}

	function collectMail() {
		if (validateEmail()) {
			email = $('#email').val()
			addToLocalStorage('USER', 'email', email)
			return true
		}
	}

	function collectPhone(element) {
		if (validatePhone(element)) {
			phone = element.val()
			phone = phone.replace(/[\s+-]+/g, '').replace(/\(/g, '').replace(/\)/g, '');
			addToLocalStorage('USER', 'phone', phone);
			sendAnalytics();
			return true;
		}
	}

	function getOrder(e) {
		goodType = e.attr('data-order')
		if (goodType == '1') {
			goodId = 247
		} else if (goodType == '2') {
			goodId = 246
		} else if (goodType == '3') {
			goodId = 248
		} else if (goodType == '4') {
			goodId = 249
		} else {
			goodId = 0;
		}
	}

	function sendAnalytics() {
		getData = {
			client_id: USER.id,
			phone: phone,
			email: email,
			brand: brand,
			white_price: whitePrice,
			cmyk_price: cmykPrice,
			prints: prints
		}
		sendData = JSON.stringify(getData);

		$.ajax({
			url: 'connect/analytics.php',
			type: "POST",
			data: {
				sendData: sendData
			},
			success: function (msg) {
				// console.log(msg);
			},
			error: function (msg) {
				// console.log(msg);
			}
		});
	}

	function sendToCrm(element) {

		if (!phone || phone == '') {
			phone = '+7 000 000 0000';
		}

		if (element == 'newClient') {
			toCrmData = {
				'site': 'dtgshop',
				'externalId': USER.id,
				'firstName': `[LP: ${USER.id}]`,
				'phones': [{
					'number': phone
				}],
				'email': email,
				'customFields': {
					'firstcallcomment': `ID на Лендинге: ${USER.id} | Печатает ${brand} по ${prints} шт. принтов в месяц.`
				},
				'contragentType': 'individual',
				'source': {
					'source': 'Landing Page'
				}
			}
			let newClientData = JSON.stringify(toCrmData);
			$.ajax({
				url: 'connect/newClientCRM.php',
				type: "POST",
				data: {
					newClientData: newClientData
				},
				beforeSend: function () {
					$('.js-sent-result').fadeIn().text('Отправляем запрос...');
				},
				success: function (msg) {
					// console.log(msg);
					$('.js-consultation-form').fadeOut();
					$('.js-sent-result').text('Заказ отправлен!');
				},
				error: function (msg) {
					$('.js-sent-result').text(msg);
				}
			});
		}

		if (element == 'newOrder') {
			toCrmData = {
				'items': [{
					'offer': {
						'externalId': goodId
					}
				}],
				'firstName': 'ПОКУПКА С LANDING PAGE',
				'phone': phone,
				'email': email,
				'shipmentStore': 'wh',
				'contragentType': 'individual',
				'orderType': 'eshop-legal',
				'orderMethod': 'landing-page',
				'source': {
					'source': 'Landing Page'
				}
			}
			let newOrderData = JSON.stringify(toCrmData);
			$.ajax({
				url: 'connect/newOrderCRM.php',
				type: "POST",
				data: {
					newOrderData: newOrderData
				},
				beforeSend: function () {
					$('.js-popup-form').fadeOut()
					$('.js-popup-result').text("Отправляем запрос...").fadeIn();
				},
				success: function (msg) {
					// console.log(msg);
					$('.js-popup-result').text("Заказ оформлен! Сейчас наш менеджер закончит танцевать от радости и Вам перезвонит! 🥳");
				},
				error: function (msg) {
					// console.log(msg);
					$('.js-popup-result').text('Ошибка. Что-то пошло не так.');
				}
			});
		}

	}

	function sendToEmail() {

		$.ajax({
			url: 'connect/mailer.php',
			type: "POST",
			data: 'query=' + sendData,
			success: function (msg) {
				console.log('success: ' + msg);
			},
			error: function (msg) {
				console.log('error: ' + msg);
			}
		})
	}

});